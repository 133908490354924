var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-0 fill-height"},[_c('v-col',{staticClass:"d-flex align-center mx-auto fill-height",attrs:{"cols":"10","lg":"5","xl":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-8",attrs:{"width":"100%"}},[(_vm.sent)?[_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"MegaBusiness Logo","max-width":"206.36"}})],1)],1),_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold primary--text"},[_vm._v(" Reset password ")]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v("Please, enter new password")]),_c('validation-observer',{ref:"reset",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.resetPassword)}}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"password","rules":"confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password","error-messages":errors,"label":"Password","placeholder":"Please, enter your new password"},model:{value:(_vm.reset.password),callback:function ($$v) {_vm.$set(_vm.reset, "password", $$v)},expression:"reset.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password_confirmation","error-messages":errors,"label":"Password confirmation","placeholder":"Please, confirm your new password"},model:{value:(_vm.reset.password_confirmation),callback:function ($$v) {_vm.$set(_vm.reset, "password_confirmation", $$v)},expression:"reset.password_confirmation"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"loading":_vm.loading,"width":"45%","height":"49","rounded":"","type":"submit","color":"primary"}},[_vm._v("reset password")])],1)],1)]}}],null,false,2890091948)})]:[_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"MegaBusiness Logo","max-width":"206.36"}})],1)],1),_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold primary--text"},[_vm._v(" Reset password ")]),_c('validation-observer',{ref:"login",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.forgotPassword)}}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","autocomplete":"email","error-messages":errors,"hint":"Provide e-mail that you were registered with","placeholder":"Please, provide your e-mail"},model:{value:(_vm.forgot.email),callback:function ($$v) {_vm.$set(_vm.forgot, "email", $$v)},expression:"forgot.email"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"loading":_vm.loading,"width":"45%","height":"49","rounded":"","type":"submit","color":"primary"}},[_vm._v("forgot password")])],1)],1)]}}])})]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }