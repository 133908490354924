<template>
  <v-row class="ma-0 pa-0 fill-height">
    <v-col cols="10" lg="5" xl="3"  class="d-flex align-center mx-auto fill-height">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-card width="100%" class="pa-8">
            <template v-if="sent">
              <v-card-title class="d-flex flex-column font-weight-bold">
                <router-link to="/">
                  <v-img src="@/assets/logo.svg" alt="MegaBusiness Logo" max-width="206.36"></v-img>
                </router-link>
              </v-card-title>
              <v-card-title class="d-flex flex-column font-weight-bold primary--text">
                Reset password
              </v-card-title>
              <v-card-subtitle class="text-center">Please, enter new password</v-card-subtitle>
              <validation-observer ref="reset" v-slot="{ passes }">
                <v-form @submit.prevent="passes(resetPassword)">
                  <v-card-text>
                    <validation-provider name="password" rules="confirmed:confirmation" v-slot="{ errors }">
                      <v-text-field type="password"
                                    autocomplete="password"
                                    :error-messages="errors"
                                    v-model="reset.password"
                                    label="Password"
                                    placeholder="Please, enter your new password"></v-text-field>
                    </validation-provider>
                    <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                      <v-text-field type="password"
                                    autocomplete="password_confirmation"
                                    :error-messages="errors"
                                    v-model="reset.password_confirmation"
                                    label="Password confirmation"
                                    placeholder="Please, confirm your new password"></v-text-field>
                    </validation-provider>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn :loading="loading" width="45%" height="49"
                           rounded type="submit"
                           color="primary"
                           class="text-capitalize">reset password</v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </template>
            <template v-else>
              <v-card-title class="d-flex flex-column font-weight-bold">
                <router-link to="/">
                  <v-img src="@/assets/logo.svg" alt="MegaBusiness Logo" max-width="206.36"></v-img>
                </router-link>
              </v-card-title>
              <v-card-title class="d-flex flex-column font-weight-bold primary--text">
                Reset password
              </v-card-title>
              <validation-observer ref="login" v-slot="{ passes }">
                <v-form @submit.prevent="passes(forgotPassword)">
                  <v-card-text>
                    <validation-provider name="email" rules="required|email" v-slot="{ errors }">
                      <v-text-field type="email"
                                    autocomplete="email"
                                    :error-messages="errors"
                                    v-model="forgot.email"
                                    hint="Provide e-mail that you were registered with"
                                    placeholder="Please, provide your e-mail"></v-text-field>
                    </validation-provider>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn :loading="loading" width="45%" height="49"
                           rounded type="submit"
                           color="primary"
                           class="text-capitalize">forgot password</v-btn>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ResetPassword",
  data () {
    return {
      loading: false,
      sent: false,
      forgot: {
        email: null
      },
      reset: {
        email: null,
        token: null,
        password: null,
        password_validation: null
      }
    }
  },
  methods: {
    forgotPassword() {
      this.loading = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/password-forgot`, this.forgot)
      .then((response) => {
        setTimeout(() => {
          this.loading = false
          this.sent = true
          this.reset.token = response.data.token
          this.reset.email = this.forgot.email
        }, 1500)
      })
    },
    resetPassword( ) {
      this.loading = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/password-reset`, this.reset)
          .then(() => {
            setTimeout(() => {
              this.loading = false
              this.$router.push({ name: "login" })
                  .then(() => {
                    this.$store.dispatch('setResponse', { status: true, type: 'success', message: 'Use your new password for login' })
                  })
            }, 1500)

          })
    }
  }
}
</script>

<style scoped lang="scss">
canvas {
  width: 100%;
  height: 100%;
}
</style>
